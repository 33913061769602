<template>
  <div>
    <div class="analyze-wrapper">
      <div class="inner">
        <div class="left-wrapper">
          <div class="title">
            Analyze Your Payments
          </div>
          <div class="content margin-top-15">
            <div>Your payments strategy is at the heart of your digital business. You want that strategy to constantly improve as your business grows. That’s why insights into your payment performance — and how you’re doing relative to your peers — is so important.</div>
            <div class="margin-top-20">moozumi lets you measure and monitor transactions across gateways to understand where you can optimize. We combine reporting tools to let you see how your payments strategy can improve — and the flexibility to optimize your strategy based on that insight.</div>
          </div>
        </div>
        <img src="../../assets/images/report_on_payment/p1.png" />
      </div>
    </div>
    <div class="content-list">
      <div class="inner">
        <div>
          <MoozumiCard :icon="cards[0].icon" :title="cards[0].title" :content="cards[0].content"></MoozumiCard>
          <MoozumiCard :icon="cards[1].icon" :title="cards[1].title" :content="cards[1].content" class="margin-top-100"></MoozumiCard>
        </div>
        <div>
          <MoozumiCard :icon="cards[2].icon" :title="cards[2].title" :content="cards[2].content" class="mobile-style"></MoozumiCard>
        </div>
      </div>
    </div>
    <ConnectwiththeexpertsinPaymentOrchestration></ConnectwiththeexpertsinPaymentOrchestration>
  </div>
</template>
<script>
import ConnectwiththeexpertsinPaymentOrchestration from '../../components/ConnectwiththeexpertsinPaymentOrchestration.vue'
import MoozumiCard from '../../components/MoozumiCard.vue'

export default {
  components: {
    ConnectwiththeexpertsinPaymentOrchestration,
    MoozumiCard
  },
  data () {
    return {
      cards: [
        {
          icon: require('../../assets/images/report_on_payment/icon1.png'),
          title: 'Provide Management Level Insights',
          content: [
            'To optimize online payments, you need deep insights into your transactions. You want powerful analytical tools and reports that help you understand your success rates, latency, and more.',
            'moozumi gives your payment teams an interface for you to view, measure, and analyze payment performance across the business. The dashboard provides a wide range of analytics, insights, and lookup capabilities for all of your transactions. This lets you continually optimize your payments strategy — and grow your revenue.'
          ]
        },
        {
          icon: require('../../assets/images/report_on_payment/icon3.png'),
          title: 'Benchmark Success Rates with Industry Standards',
          content: [
            'How well do your transaction success rates compare to the industry? Are your payments latency rates good for your customers? To strengthen your payments strategy you need to compare your results with other merchants and peers.',
            'moozumi combines a vendor-independent position across hundreds of payment services and billions of transactions each year. That means we have an unmatched perspective on gateway performance. Take advantage of our rich, cross-provider data set to optimize your payments strategy.'
          ]
        },
        {
          icon: require('../../assets/images/report_on_payment/icon2.png'),
          title: 'Dive Deep into Transactions',
          content: [
            'You want to stay on top of transaction success rates across payment gateways. That can help your developers to fix issues and generally tune your application.',
            'moozumi’s reporting capabilities allow users to dive deep into the results of individual transactions, payment methods, and their performance. You can even link metadata with payment records to better analyze transactions from a business perspective.'
          ]
        }
      ]
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../styles/variables.scss';

.analyze-wrapper {
  padding: 113px 0 111px;
  background: url(../../assets/images/report_on_payment/banner.png);
  background-size: cover;
  .inner {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .left-wrapper {
      width: 590px;
      max-width: 100%;
      .title {
        font-family: Helvetica-Bold;
        font-size: 52px;
        color: $primary-color1;
        letter-spacing: 0;
        text-align: left;
        line-height: 68px;
      }
      .content {
        font-family: HelveticaNeue;
        font-size: 18px;
        color: $content-color;
        letter-spacing: 0;
        text-align: left;
        line-height: 36px;
      }
    }
    img {
      width: 530px;
      max-width: 100%;
      box-sizing: border-box;
    }
  }
}
.content-list {
  background: #F4F8FF;
  padding: 120px 0;
  .inner {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    &>div {
      width: 575px;
      max-width: 100%;
      box-sizing: border-box;
    }
  }
}
@media screen and (max-width: 1200px){
  .analyze-wrapper {
    .left-wrapper {
      padding: 0 15px;
    }
    img {
      margin-top: 20px;
    }
  }
  .content-list {
    .inner {
      &>div {
        padding: 0 15px;
      }
      .moozumi-card {
        &.mobile-style {
          margin-top: 100px;
        }
      }
    }
  }
}
</style>
